<template>
  <div class="modal fade show" style="display: block;" >
    <div class="modal-dialog" >
      <div class="modal-content" >
        <div class="modal-body">
          <div class="row-item status-head" >
            <div class="row-item" @click="showStatusModal">
            <div class="store" :class="dynamicClass">{{ getStateText(reservation.state) }}</div>
            <div class="status">ステータス変更</div>
            </div>
              <div class="show-reservation" @click='showModal'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.27614 3.33333H6.66667V2H2V6.66667H3.33333V4.27614L6.19526 7.13807L7.13807 6.19526L4.27614 3.33333ZM14 9.33333H12.6667V11.7239L9.80473 8.86193L8.86193 9.80473L11.7239 12.6667H9.33333V14H14V9.33333Z" fill="#009bdc"/>
                </svg>
                詳細を見る
              </div>
          </div>
          <div class="row-item">
            <div class="name">{{reservation.last_name}}{{reservation.first_name}}
              <span v-if="customerEdit.count_reservation">
              <span class="square-number" v-if="customerEdit.count_reservation == 1">初</span>
              <span class="square-number" v-else-if="customerEdit.count_reservation <= 9">{{customerEdit.count_reservation }}</span>
              <span class="square-number" v-else>多</span>
            </span>
            </div>
          </div>
          <div class="row-item">
            <div>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.125 10.2625V12.4726C13.125 12.8007 12.8713 13.0729 12.5441 13.0961C12.2707 13.1154 12.0477 13.125 11.875 13.125C6.35212 13.125 1.875 8.64788 1.875 3.125C1.875 2.95232 1.88466 2.72929 1.90397 2.45594C1.92711 2.12868 2.19934 1.875 2.52741 1.875H4.73756C4.89799 1.875 5.03235 1.99651 5.04846 2.15613C5.06292 2.29942 5.07636 2.41446 5.08879 2.50126C5.21522 3.3842 5.47346 4.2246 5.84294 5.00189C5.90224 5.12666 5.86357 5.27599 5.75116 5.35629L4.40222 6.31988C5.22345 8.23819 6.76181 9.77656 8.68012 10.5978L9.64194 9.25119C9.72325 9.13744 9.87437 9.09831 10.0006 9.15825C10.7779 9.52744 11.6182 9.78538 12.501 9.9115C12.5872 9.92387 12.7016 9.93719 12.8439 9.95156C13.0035 9.96769 13.125 10.1021 13.125 10.2625Z" fill="var(--color-metal-grey)"/>
              </svg>
            </div>
            <div>{{reservation.tel}}</div>
          </div>

          <div class="row-item">
            <div class="" v-for="(tag, index) in showReservationTags" :key="index" :title="tag.name">
              <div v-if="tag.icon">
                <div v-if="tag.icon === 'crown'" class="icon-outer-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 160 160" class="tag-images">
                    <g id="グループ_400" data-name="グループ 400" transform="translate(530 -3683)">
                      <path id="パス_114" data-name="パス 114" d="M7.211,32.291a7.113,7.113,0,0,0,.737-.074l3.8,42.791H128.257l3.8-42.791a7.118,7.118,0,0,0,.736.074,7.221,7.221,0,1,0-5.068-2.087l-26.3,26.3L72.628,13.911a7.212,7.212,0,1,0-5.256,0L38.577,56.5l-26.3-26.3a7.207,7.207,0,1,0-5.068,2.087" transform="translate(-520 3712)" fill="#ffffff"/>
                      <path id="パス_115" data-name="パス 115" d="M24.675,166.285l1.485,16.742H137.886l1.485-16.742Z" transform="translate(-532.023 3630.978)" fill="#ffffff"/>
                      <rect id="長方形_653" data-name="長方形 653" width="16" height="16" transform="translate(-530 3683)" fill="none"/>
                    </g>
                  </svg>
                  <div class="tag-name">{{ tag.name }}</div>
                </div>
                <div v-if="tag.icon === 'coupon'" class="icon-outer-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 160 160" class="tag-images">
                    <g id="グループ_402" data-name="グループ 402" transform="translate(733 -3404)">
                      <path id="前面オブジェクトで型抜き_1" data-name="前面オブジェクトで型抜き 1" d="M128.175,81.452H11.824A11.825,11.825,0,0,1,0,69.629V52.98a12.4,12.4,0,0,0,7.564-4.108,12.356,12.356,0,0,0,3.061-8.145,12.357,12.357,0,0,0-3.061-8.146A12.41,12.41,0,0,0,0,28.472V11.825A11.824,11.824,0,0,1,11.824,0H128.175A11.825,11.825,0,0,1,140,11.825V28.361a12.378,12.378,0,0,0,0,24.729V69.629a11.825,11.825,0,0,1-11.824,11.823ZM29.159,63.381v10.61h5.068V63.381Zm30.6-38.735a12.682,12.682,0,0,0-5.37,1.138,11.923,11.923,0,0,0-4.17,3.227c-2.395,2.874-3.66,6.884-3.66,11.6,0,4.562,1.2,8.45,3.475,11.244a11.613,11.613,0,0,0,9.325,4.315,12.082,12.082,0,0,0,9.37-4.03c2.48-2.816,3.79-6.969,3.79-12.01a21.252,21.252,0,0,0-.852-6.131,14.818,14.818,0,0,0-2.483-4.909A11.545,11.545,0,0,0,59.76,24.646Zm38.64.481v30.6h4.64V42.286h10.48V38.446H103.04v-9.44h11.28v-3.88Zm-21.119,0v30.6H81.92V42.286H92.4V38.446H81.92v-9.44H93.2v-3.88ZM29.159,44.741v10.61h5.068V44.741Zm0-18.643V36.711h5.068V26.1Zm0-18.637V18.07h5.068V7.461Zm30.361,44.9A6.924,6.924,0,0,1,53.5,48.751a15.929,15.929,0,0,1-2.06-8.265c0-5.993,2.523-12.04,8.159-12.04a6.9,6.9,0,0,1,6.075,3.73,16.135,16.135,0,0,1,1.965,8.11,16.457,16.457,0,0,1-2.05,8.485A6.936,6.936,0,0,1,59.52,52.366Z" transform="translate(-724 3443)" fill="#ffffff"/>
                      <rect id="長方形_655" data-name="長方形 655" width="16" height="16" transform="translate(-733 3404)" fill="none"/>
                    </g>
                  </svg>
                  <div class="tag-name">{{ tag.name }}</div>
                </div>
                <div v-if="tag.icon === 'cheers'" class="icon-outer-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 160 160" class="tag-images">
                    <g id="グループ_401" data-name="グループ 401" transform="translate(447 -3404)">
                      <path id="パス_116" data-name="パス 116" d="M60.682,61,23.425,51.782a3.883,3.883,0,0,0-4.707,2.841l-13.1,52.925a23.064,23.064,0,0,0,13.165,26.67l-6.662,26.924-7.3-1.808a3.89,3.89,0,0,0-1.869,7.552l22.159,5.483a3.888,3.888,0,0,0,1.869-7.548l-7.309-1.81,6.663-26.926a23.067,23.067,0,0,0,24.09-17.45l13.1-52.926A3.889,3.889,0,0,0,60.682,61M50.371,86.488,20.664,79.137l4.669-18.871,29.707,7.349Z" transform="translate(-435.755 3381.518)" fill="#ffffff"/>
                      <path id="パス_117" data-name="パス 117" d="M262.463,162.176a3.891,3.891,0,0,0-4.709-2.843l-7.3,1.808-6.661-26.924a23.067,23.067,0,0,0,13.167-26.67l-13.1-52.925a3.883,3.883,0,0,0-4.707-2.841L201.9,61a3.889,3.889,0,0,0-2.841,4.709l13.1,52.926a23.067,23.067,0,0,0,24.09,17.45L242.9,163.01l-7.309,1.81a3.888,3.888,0,0,0,1.869,7.548l22.159-5.483a3.889,3.889,0,0,0,2.839-4.709M212.207,86.488l-4.669-18.873,29.707-7.349,4.669,18.871Z" transform="translate(-560.823 3381.518)" fill="#ffffff"/>
                      <path id="パス_118" data-name="パス 118" d="M180.219,0A2.917,2.917,0,0,0,177.3,2.916V9.234a2.916,2.916,0,1,0,5.833,0V2.916A2.917,2.917,0,0,0,180.219,0" transform="translate(-547.219 3414)" fill="#ffffff"/>
                      <path id="パス_119" data-name="パス 119" d="M125.994,21.64a2.916,2.916,0,1,0,4.671-3.492l-4.322-5.78a2.917,2.917,0,0,0-4.671,3.494Z" transform="translate(-511.881 3406.96)" fill="#ffffff"/>
                      <path id="パス_120" data-name="パス 120" d="M227.127,21.64l4.322-5.779a2.916,2.916,0,0,0-4.671-3.494l-4.322,5.78a2.916,2.916,0,1,0,4.671,3.492" transform="translate(-575.24 3406.96)" fill="#ffffff"/>
                      <rect id="長方形_654" data-name="長方形 654" width="16" height="16" transform="translate(-447 3404)" fill="none"/>
                    </g>
                  </svg>
                  <div class="tag-name">{{ tag.name }}</div>
                </div>
                <div v-if="tag.icon !== 'coupon' && tag.icon !== 'crown' && tag.icon !== 'cheers'" class="icon-outer-wrapper">
                  <div class="icon-inner-wrapper tag-images">
                    <i :class="[tag.icon, 'pe-auto']" style="color: #ffffff;font-size: 10px" aria-hidden="true"></i>
                  </div>
                  <div class="tag-name">{{ tag.name }}</div>
                </div>
              </div>
              <div v-else class="tag-images text-center p-2" style="background-color: transparent;">
                <div>{{ tag.name }}</div>
              </div>
            </div>
          </div>

          <div class="row-item">
            <div class="time">日時：{{formatDate(reservation.start_time)}}</div>
            <div>最終来店：{{customerEdit.count_reservation}}</div>
          </div>
          <div class="row-item">
            <div>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.66634 5.9987C5.58681 5.9987 6.33301 5.2525 6.33301 4.33203C6.33301 3.41156 5.58681 2.66536 4.66634 2.66536C3.74587 2.66536 2.99967 3.41156 2.99967 4.33203C2.99967 5.2525 3.74587 5.9987 4.66634 5.9987ZM4.66634 7.33203C3.00949 7.33203 1.66634 5.98888 1.66634 4.33203C1.66634 2.67518 3.00949 1.33203 4.66634 1.33203C6.32319 1.33203 7.66634 2.67518 7.66634 4.33203C7.66634 5.98888 6.32319 7.33203 4.66634 7.33203ZM11.6663 8.66536C12.4027 8.66536 12.9997 8.06843 12.9997 7.33203C12.9997 6.59565 12.4027 5.9987 11.6663 5.9987C10.9299 5.9987 10.333 6.59565 10.333 7.33203C10.333 8.06843 10.9299 8.66536 11.6663 8.66536ZM11.6663 9.9987C10.1936 9.9987 8.99967 8.80476 8.99967 7.33203C8.99967 5.85927 10.1936 4.66536 11.6663 4.66536C13.1391 4.66536 14.333 5.85927 14.333 7.33203C14.333 8.80476 13.1391 9.9987 11.6663 9.9987ZM13.333 13.9987V13.6654C13.333 12.7449 12.5868 11.9987 11.6663 11.9987C10.7459 11.9987 9.99967 12.7449 9.99967 13.6654V13.9987H8.66634V13.6654C8.66634 12.0085 10.0095 10.6654 11.6663 10.6654C13.3232 10.6654 14.6663 12.0085 14.6663 13.6654V13.9987H13.333ZM6.66634 13.9987V11.332C6.66634 10.2274 5.77091 9.33203 4.66634 9.33203C3.56177 9.33203 2.66634 10.2274 2.66634 11.332V13.9987H1.33301V11.332C1.33301 9.4911 2.82539 7.9987 4.66634 7.9987C6.50729 7.9987 7.99967 9.4911 7.99967 11.332V13.9987H6.66634Z" fill="var(--color-metal-grey)"/>
              </svg>
            </div>
            <div>{{reservation.number_of_people}}名(うち、子供{{reservation.number_of_children}}名)</div>
          </div>
          <div class="row-item">
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.33203V14.6654H12.6667V9.33203H10.6667V4.66536C10.6667 2.82442 12.1591 1.33203 14 1.33203ZM6 9.26536V14.6654H4.66667V9.26536C3.14517 8.9565 2 7.61136 2 5.9987V1.9987H3.33333V6.66536H4.66667V1.9987H6V6.66536H7.33333V1.9987H8.66667V5.9987C8.66667 7.61136 7.52147 8.9565 6 9.26536Z" fill="var(--color-metal-grey)"/>
            </svg>
          </div>
          <div>{{reservation.course_name}}</div>
        </div>
          <div class="row-item">
            <div>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.33333 12.5924H4.27614L10.4853 6.38321L9.54247 5.4404L3.33333 11.6496V12.5924ZM14 13.9257H2V11.0972L10.9567 2.14057C11.2171 1.88023 11.6391 1.88023 11.8995 2.14057L13.7851 4.02619C14.0455 4.28654 14.0455 4.70865 13.7851 4.969L6.16176 12.5924H14V13.9257ZM10.4853 4.4976L11.4281 5.4404L12.3709 4.4976L11.4281 3.55479L10.4853 4.4976Z" fill="var(--color-metal-grey)"/>
              </svg>
            </div>
            <div class="memo">{{reservation.memo}}</div>
          </div>
          <div class="row-item">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20" >
                <!-- Speech bubble -->
                <rect x="10" y="10" width="80" height="50" rx="10" ry="10" fill="#333333" />
                <!-- Speech tail -->
                <polygon points="30,60 50,60 40,80" fill="#333333" />
                <!-- Three dots -->
                <circle cx="40" cy="35" r="5" fill="white" />
                <circle cx="50" cy="35" r="5" fill="white" />
                <circle cx="60" cy="35" r="5" fill="white" />
              </svg>
            </div>
            <div class="memo">


              {{reservation.memo_from_customer}}
          </div>
          </div>

          <div class="row-item">
            <div>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.66634 5.33203V10.6654C8.66634 11.77 7.77094 12.6654 6.66634 12.6654H5.2192C4.94465 13.4422 4.20382 13.9987 3.33301 13.9987C2.22844 13.9987 1.33301 13.1033 1.33301 11.9987C1.33301 10.8941 2.22844 9.9987 3.33301 9.9987C4.20382 9.9987 4.94465 10.5552 5.2192 11.332H6.66634C7.03454 11.332 7.33301 11.0336 7.33301 10.6654V5.33203C7.33301 4.22746 8.22841 3.33203 9.33301 3.33203H11.333V1.33203L14.6663 3.9987L11.333 6.66536V4.66536H9.33301C8.96481 4.66536 8.66634 4.96384 8.66634 5.33203ZM3.33301 12.6654C3.70119 12.6654 3.99967 12.3669 3.99967 11.9987C3.99967 11.6305 3.70119 11.332 3.33301 11.332C2.96482 11.332 2.66634 11.6305 2.66634 11.9987C2.66634 12.3669 2.96482 12.6654 3.33301 12.6654Z" fill="var(--color-metal-grey)"/>
              </svg>
            </div>
            <div>{{reservation.reservation_type_label}}</div>
          </div>
        </div>
        <div class="modal-footer">
          <span class="cursor-pointer" @click='showPeoplesModal'>
            <svg width="18" height="17" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 5px;">
              <path d="M3.66671 5.00004C4.58718 5.00004 5.33337 4.25385 5.33337 3.33337C5.33337 2.4129 4.58718 1.66671 3.66671 1.66671C2.74623 1.66671 2.00004 2.4129 2.00004 3.33337C2.00004 4.25385 2.74623 5.00004 3.66671 5.00004ZM3.66671 6.33337C2.00985 6.33337 0.666707 4.99023 0.666707 3.33337C0.666707 1.67652 2.00985 0.333374 3.66671 0.333374C5.32356 0.333374 6.66671 1.67652 6.66671 3.33337C6.66671 4.99023 5.32356 6.33337 3.66671 6.33337ZM10.6667 7.66671C11.4031 7.66671 12 7.06977 12 6.33337C12 5.59699 11.4031 5.00004 10.6667 5.00004C9.93031 5.00004 9.33337 5.59699 9.33337 6.33337C9.33337 7.06977 9.93031 7.66671 10.6667 7.66671ZM10.6667 9.00004C9.19397 9.00004 8.00004 7.80611 8.00004 6.33337C8.00004 4.86061 9.19397 3.66671 10.6667 3.66671C12.1394 3.66671 13.3334 4.86061 13.3334 6.33337C13.3334 7.80611 12.1394 9.00004 10.6667 9.00004ZM12.3334 13V12.6667C12.3334 11.7462 11.5872 11 10.6667 11C9.74624 11 9.00004 11.7462 9.00004 12.6667V13H7.66671V12.6667C7.66671 11.0098 9.00984 9.66671 10.6667 9.66671C12.3236 9.66671 13.6667 11.0098 13.6667 12.6667V13H12.3334ZM5.66671 13V10.3334C5.66671 9.22877 4.77127 8.33337 3.66671 8.33337C2.56214 8.33337 1.66671 9.22877 1.66671 10.3334V13H0.333374V10.3334C0.333374 8.49244 1.82576 7.00004 3.66671 7.00004C5.50765 7.00004 7.00004 8.49244 7.00004 10.3334V13H5.66671Z" fill="#009BDC"/>
            </svg>
           <span class="edit-reservation">人数</span>
          </span>
          <span class="cursor-pointer" @click='openSteps' style="padding-left: 1rem">
            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 4px;">
              <path d="M8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667ZM8.00004 13.3334C10.9456 13.3334 13.3334 10.9456 13.3334 8.00004C13.3334 5.05452 10.9456 2.66671 8.00004 2.66671C5.05452 2.66671 2.66671 5.05452 2.66671 8.00004C2.66671 10.9456 5.05452 13.3334 8.00004 13.3334ZM8.66671 8.00004H11.3334V9.33337H7.33337V4.66671H8.66671V8.00004Z" fill="#009BDC"/>
            </svg>
            <span class="edit-reservation">日時・テーブル</span>
          </span>
        </div>
      </div>

    </div>
    <div class="modal-backdrop show"></div>
    <div class="modal-backdrop no-opacity" @click="onClose"></div>
    <StatusChangeModal
        v-show="showStatusChangeModal"
        @statusSelected="setStatus"
        @close="onClose"
        @reload="$emit('reload');"
        :reservation="reservation"
        :shop="shop"
    />
  </div>
</template>

<script>
import StatusChangeModal from "./StatusChangeModal.vue";
import ReservationBlockModal from "../../components/ReservationBlockModal.vue";
import ColorSelectComponent from "../../components/ColorSelectComponent.vue";
import EditPeoplesModal from "./EditPeoplesModal.vue";
import moment from 'moment';
import Http from "../../../shared/http"

export default {
  components: {ColorSelectComponent, ReservationBlockModal, StatusChangeModal, EditPeoplesModal},
  props: {
    reservation: {
      type: Object,
    },
    showReservationTags: {
      type: Array,
    },
    customerEdit: {
      type: Object,
    },
  },
  data() {
    return {
      showStatusChangeModal: false,
      showEditPeoplesModal: false,
      shop: {},
      state: {},
      isPlaying: false,
      audioSrc: '',
      slug: '',
    };
  },
  computed: {
    dynamicClass(){
      return this.reservation.state
    }
  },
  methods: {
    openSteps() {
      const date = moment(new Date(this.reservation.start_time)).format('YYYY-MM-DD'); // Assuming `date` is a property of `reservation`
      window.location.href = `/shop_manager/time_table/${this.reservation.id}/edit`;
    },
    onClose() {
      this.showStatusChangeModal = false;
      this.$emit('close');
    },
    showModal() {
      this.$emit('show-modal', this.reservation);
    },
    showPeoplesModal() {
      this.$emit('show-peoples-modal', this.reservation);
    },
    async showStatusModal() {
      await this.get_shop_detail()
      this.showStatusChangeModal = true;
    },
    setStatus(val) {
      this.state = val;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },
    async get_shop_detail() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      try {
        const response = await Http.get(`${slug}/shop_manager/time_table/shop_detail`);
        this.shop = response.data.shop;
      } catch (error) {
        console.error(error);
      }
    },
    getStateText(state) {
      switch (state) {
        case 'reserved':
          return '来店待ち';
        case 'seating':
          return '来店';
        case 'finished':
          return '会計済';
        case 'not_show':
          return 'No Show';
        case 'cancelled':
          return '予約取消';
        default:
          return '';
      }
    },
  },
}
</script>
<style scoped>
.edit-reservation {
  color: var(--color-reserved-border);
}
.modal-dialog {
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.modal-content{
  min-height: calc(100vh - 200px);
  border-radius: 12px;
}
.modal-body{
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-metal-grey);
}
.row-item{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.store{
  background: var(--main_opacity20);
  padding: 8px 35px;
  border-radius: 5px;
  color: var(--color-text-reservation);
  font-weight: bold;
}
.status-head{
  cursor: pointer;
}
.status{
  color: var(--color-reserved-border);
  cursor: pointer;
}
.modal-footer{
  margin-left: 15px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
.button{
  border: 2px solid var(--color-main);
  color: var(--color-main);
  padding: 10px 30px;
  background: var(--color-white);
  border-radius: 5px;
  font-weight: bold;
}
.name{
  color: var(--color-metal-grey);
  font-weight: bold;
  font-size: 22px;
  width: 90%;
  word-break: break-all;
  overflow-wrap: break-word;
}
.square-number{
  background-color: var(--color-metal-grey);
  color: var(--color-white);
  border-radius: 3px;
  padding: 0px 10px;
  font-weight: bold;
}
.time{
  color: var(--color-metal-grey);
}
.tag-images{
  background: var(--color-metal-grey);
  padding: 1px;
  border-radius: 3px;
  max-width: 100%;
}
.icon-outer-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.icon-inner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}
.tag-name{
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  width: 50px;
  text-overflow: ellipsis;
}
.seating {
  background-color:var(--color-sit-down-opacity);
  border: 4px solid var(--color-seating-border);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
}
.reserved {
  background-color:var( --color-reserved-opacity);
  border: 4px solid var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-reserved-border)
}
.finished{
  background-color: var(--color-accounted-for);
  border: 4px solid var(--color-medium-grey);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
}
.cancelled{
  background-color: var(--color-reservation-cancel);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.not_show {
  background-color: var(--color-no-show);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.memo{
  width:90%;
  word-break: break-all;
  overflow-wrap: break-word;
}
@media only screen
and (min-device-width: 768px){
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }
  .modal-content{
    min-height: calc(100vh - 500px);
  }
}
.show-reservation {
  margin-left: 7rem;
  color: var(--color-reserved-border);
}
</style>
